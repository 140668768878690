import {SpeakerCalendar} from "../../components/SpeakerCalendar";

let speaker_slot_template = require('./speaker_slot.twig');
let speaker_selection_container_template = require('./speaker_selection_container.twig');
let speaker_bio_modal_template = require('./speaker_bio_modal.twig');

export class SpeakerSlot
{
    constructor() {
        this.speakerData = {};
        this.topic_descriptions = [];
        this.topic_length_attestation = false;

        this.project_id = $('#project_dd').val();
        this.program_type_id = $('#program_type_dd').val();
        let program_name  = app.appData.projects[this.project_id].program_types[this.program_type_id].program_name;
        let program_key  = app.appData.projects[this.project_id].program_types[this.program_type_id].program_key;
        let event_type = app.appData.projects[this.project_id].event_code;
        let virtual_grp_regex = /Virtual Group Program/;
        let topic_Attestation_Flag = (typeof app.appData.layout.meeting_request.enable_topic_length_attestation == 'undefined') ? false : app.appData.layout.meeting_request.enable_topic_length_attestation;
        this.attestation_config = (typeof app.appData.configs.TOPIC_LENGTH_ATTESTATION == 'undefined') ? {} : JSON.parse(app.appData.configs.TOPIC_LENGTH_ATTESTATION);
        if (Object.keys(this.attestation_config).length > 0 && topic_Attestation_Flag)
        {
          if (this.attestation_config.event_types.includes(event_type) &&
            !this.attestation_config.exclude_program_types.includes(program_key))
          {
            this.topic_length_attestation = true;
          }
        }

        let project_program_type_config = app.appData.projects[this.project_id].program_types[this.program_type_id];
        let required_alt_HCP_speakers = project_program_type_config.configuration.sb.meeting_request.required_alt_HCP_speakers ?? 0;
        let required_alt_patient_speakers = project_program_type_config.configuration.sb.meeting_request.required_alt_patient_speakers ?? 0;

        let speaker_preference = (typeof app.appData.layout.meeting_request.speaker_preference == "undefined") ? 'normal' : app.appData.layout.meeting_request.speaker_preference;
        let event_type_id = app.pages.meetingrequest.formCards.project_card.event_type_id;
        let event_type_subcode;
        this.show_non_local_speaker_rationale = (typeof app.appData.layout.meeting_request.show_non_local_speaker_rationale == "undefined") ? false : app.appData.layout.meeting_request.show_non_local_speaker_rationale;

        if (virtual_grp_regex.test(program_name)) {
            this.show_non_local_speaker_rationale = false;
        }

        Object.values(app.appData.eventTypes).forEach(category => {
            Object.values(category).forEach(event_type => {
                if (event_type.id == event_type_id) {
                    event_type_subcode = event_type.sub_code;
                }
            })
        })

        let show_patient_speaker_dd = event_type_subcode == 'PTNTPGM';

        this.dom = $(twig({ data: speaker_slot_template }).render({
            labels : app.appData.labels,
            topic_duration: (typeof app.appData.opco_config.sb.meeting_request.show_topic_duration == "undefined") ? false : app.appData.opco_config.sb.meeting_request.show_topic_duration,
            topic_attestation: this.topic_length_attestation,
            show_remove_button : !project_program_type_config.configuration.sb.meeting_request.is_spoke
        }))

        this.dom.find('.speaker_selections_container').append($(twig({ data: speaker_selection_container_template }).render({
            priority : speaker_preference == 'normal' ? 'Preferred' : '',
            speaker_preference : speaker_preference,
            required : '*',
            patient  : false,
            show_speaker_bio: app.appData.layout.meeting_request.speaker_bio_template,
            show_speaker_travel_contact: app.appData.layout.meeting_request.show_speaker_travel_contact,
            labels : app.appData.labels,
            speaker_loc : app.appData.layout.meeting_request.meetingtype_venuetype_filter ?? 0,
            show_non_local_speaker_rationale : this.show_non_local_speaker_rationale,
            show_speaker_detail_button: app.appData.permissions.speakers
        })));

        while(required_alt_HCP_speakers > 0){
            this.addAlternateSpeakerDD(speaker_preference, true, false, 'speaker_selections_container');
            required_alt_HCP_speakers--;
        }

        if (show_patient_speaker_dd) {
            this.dom.find('.patient_speaker_selections_container').append($(twig({ data: speaker_selection_container_template }).render({
                priority : speaker_preference == 'normal' ? 'Preferred' : '',
                speaker_preference : speaker_preference,
                required : '*',
                patient  : true,
                show_speaker_bio: false,
                show_speaker_travel_contact: app.appData.layout.meeting_request.show_speaker_travel_contact,
                labels: app.appData.labels,
                show_non_local_speaker_rationale: this.show_non_local_speaker_rationale
            })));

            this.dom.find('.add_alt_patient_speaker').show();

            while(required_alt_patient_speakers > 0){
                this.addAlternateSpeakerDD(speaker_preference, true, true, 'patient_speaker_selections_container');
                required_alt_patient_speakers--;
            }
        }

        if (this.show_non_local_speaker_rationale) {
            this.addRationale();
            this.dom.find('.rationale-dropdown').hide();
        }

        this.dom.on('change', '.rationale_dd', (event) => {
            if($(event.target).closest('.rationale').find('.rationale_dd').val() !== "") {
                $(event.target).closest('.rationale').find('.rationale_dd').removeClass("required is-invalid");
                this.dom.trigger('SpeakerChanged');
            } else {
                $(event.target).closest('.rationale').find('.rationale_dd').addClass("required is-invalid");
            }
        });

        this.dom.on('click', '.add_alt_speaker', () => {
            this.addAlternateSpeakerDD(speaker_preference, false, false, 'speaker_selections_container');
        });

        this.dom.on('click', '.add_alt_patient_speaker', () => {
            this.addAlternateSpeakerDD(speaker_preference, false, true, 'patient_speaker_selections_container');
        })

        this.dom.on('click', '.speaker_calendar_button', (event) => {
            let speaker_id = $(event.target).closest('.form-row').find('.speaker_dd').val();
            new SpeakerCalendar(speaker_id);
        })

        this.dom.on('click', '.speaker_details_button', (event) => {
            let speaker_id = $(event.target).closest('.form-row').find('.speaker_dd').val();
            app.navigate('speaker', [speaker_id]);
        })

        this.dom.on('click', '.speaker_bio_btn', (event) => {
            let speaker_id = $(event.target).closest('.speaker_selection_container').find('.speaker_dd').val();
            this.displaySpeakerBioFile(speaker_id);
        });

        this.topic_dd = $(this.dom.find('.topic_dd')[0]);
        this.dd_topic = this.dom.find('.topic_dd');
        this.drawTopicDD();

        this.dom.on('click', '.remove_topic_button', () => {
            this.dom.trigger($.Event('SpeakerRemoved', {slot: this}));
            this.dom.remove();
            app.pages.meetingrequest.dom.trigger('UpdateSpeakerSideCards');
        })

        this.dom.on('click', '.need_travel_arrangements', (event) => {
            $(event.target).is(':checked')
                ? $(event.target).parent().next('.speaker_travel_selection').show()
                : $(event.target).parent().next('.speaker_travel_selection').hide()
        })


        this.dom.on('change', '.speaker_dd', (event) => {
            let speaker_id = $(event.target).val();
            let speaker_dd = $(event.target);
            if(speaker_id != '')
            {
                this.getSpeakerDetails(speaker_id)
                    .then((data) => {
                        this.speakerData[speaker_id] = data;
                        this.fillSpeakerAddressInfo(data,speaker_dd);
                        this.toggleRationale('speaker_dd', speaker_dd);
                        this.localCapValidation(speaker_dd, data);
                        this.thresholdValidation(speaker_dd, data);
                        this.dom.trigger('SpeakerChanged');
                    })
            } else {
                this.dom.trigger('SpeakerChanged');
            }
        })

        this.dom.on('change', '.speaker_location_dd', () => {
            this.dom.trigger('SpeakerLocChanged');
        })

        this.dom.on('change', '.patient_speaker_dd', (event) => {
            let speaker_id = $(event.target).val();
            let speaker_dd = $(event.target);
            this.getSpeakerDetails(speaker_id)
                .then((data) => {
                    this.speakerData[speaker_id] = data;
                    this.fillPatientSpeakerAddressInfo(data,speaker_dd);
                    this.toggleRationale('patient_speaker_dd', speaker_dd);
                    this.dom.trigger('SpeakerChanged');
                })
        })

        this.reassignSpeakerPreferenceRanks();
        this.reassignSpeakerPreferenceRanks(true);
    }

    localCapValidation(speaker_dd = '', speaker_data = {}) {
        this.dom.find(".warning-local-cap-reached").addClass("d-none");

        if(speaker_dd.length && Object.keys(speaker_data).length){
            speaker_dd.removeClass("speaker_local_cap_maxed");

            if ($("#hub_meeting_id").val() == "" &&
                (speaker_data.speaker_status_key == 'apprv_cap'
                    || (speaker_data.local_cap.exclude_capped_speaker
                        && speaker_data.local_cap.localcap > 0
                        && speaker_data.local_cap.count >= speaker_data.local_cap.localcap)
                )) {
                this.dom.find(".warning-local-cap-reached").removeClass("d-none");
                speaker_dd.addClass("speaker_local_cap_maxed");
            }
        }

        this.dom.find(".speaker_dd").each((i, el) => {
            if($(el).hasClass( "speaker_local_cap_maxed")){
                this.dom.find(".warning-local-cap-reached").removeClass("d-none");
                return false;
            }
        });
    }

    thresholdValidation(speaker_dd = '', speaker_data = {}) {
        if(speaker_dd.val() != '' && $("#hub_meeting_id").val() == '')
        {
            this.dom.find(".warning-threshold-reached").addClass("d-none");
            if (this.isThresholdMet(speaker_data)) {
                this.dom.find(".warning-threshold-reached").removeClass("d-none");
                speaker_dd.val('').trigger('change');
            }
        }
    }

    isThresholdMet(speaker_data = {}) {
        if(Object.keys(speaker_data).length > 0)
        {
            let maxExpenses = parseInt(speaker_data.contracted?.threshold_amount);
            let maxMeetings = parseInt(speaker_data.contracted?.threshold_meetings);
            if((maxExpenses > 0 && maxExpenses <= parseInt(speaker_data?.contracted.expenses)) ||
                (maxMeetings > 0 && maxMeetings <= parseInt(speaker_data?.contracted.count_meetings)))
            {
                return true;
            }
        }

        return false;
    }

    displaySpeakerBioFile(speaker_id) {
        let speaker_bio_modal;
        let pdf;

        this.getSpeakerDetails(speaker_id).then(
            (details) => {
                if(details.bio_file_id) {
                    return (new $.Deferred()).resolve(details.bio_file_id);
                }

                return (new $.Deferred()).reject();
            },
            () => {
                return (new $.Deferred()).reject();
            }
        ).then(
            (bio_file_id) => {
                let renderData = {
                    file_id: bio_file_id,
                    speaker_id: speaker_id
                };

                speaker_bio_modal = $(twig({data: speaker_bio_modal_template}).render(renderData));

                speaker_bio_modal.on('hidden.bs.modal', () => {
                    pdf.destroy();
                    $('#speakerBioModal').remove();
                });

                return pdfjs.getDocument("api/meetingrequest/speakerbio?speaker_id=" + speaker_id + "&bio_file_id=" + bio_file_id).promise;
            },
            () => {
                return (new $.Deferred()).reject();
            }
        ).then(
            (pdfData) => {
                pdf = pdfData;
                return pdfData.getPage(1);
            },
            () => {
                return (new $.Deferred()).reject();
            }
        ).then(
            (page) => {
                let viewport = page.getViewport({scale: 1});

                let canvas = speaker_bio_modal.find('#pdf_canvas');
                let context = canvas[0].getContext('2d');

                canvas[0].height = viewport.height;
                canvas[0].width = viewport.width;

                return page.render({
                    canvasContext: context,
                    viewport: viewport
                });
            },
            () => {
                return (new $.Deferred()).reject();
            }
        ).then(
            () => {
                let modal = new bootstrap.Modal(speaker_bio_modal);
                modal.show();
            },
            () => {
                alert("Failed to load bio. Please check whether the speaker has created their bio.");
            }
        );
    }

    fillSpeakerAddressInfo(data,speaker_dd = '') {
        let address = data.address1 + ' ' + data.city + ', ' + data.state + ' ' + data.zip + ' ' + data.country;
        speaker_dd.closest('.form-group').find('.rec_address').val(address);

    }
    fillPatientSpeakerAddressInfo(data,speaker_dd = '') {
        let address = data.address1 + ' ' + data.city + ', ' + data.state + ' ' + data.zip + ' ' + data.country;
        speaker_dd.closest('.form-group').find('.rec_address').val(address);
    }

    addRationale() {
        this.modal = this.dom.find('.rationale-dropdown select');
        this.modal.select2();
    }

    toggleRationale(speaker_type, speaker_dd) {
        if (this.show_non_local_speaker_rationale) {
            let selectElement = '';
            if (speaker_type == 'speaker_dd') {
                selectElement = speaker_dd.closest('.speaker_selection_container').find(".speaker_dd");
            } else {
                selectElement = speaker_dd.closest('.patient_speaker_selections_container').find(".patient_speaker_dd");
            }
            if (selectElement.find(":selected").length > 0) {
                let optgroup = selectElement.find(":selected").parent();
                if (optgroup.attr('label') === "Non-Local") {
                    speaker_dd.closest('.speaker_selection_container').find('.rationale_dd').addClass("required is-invalid");
                    speaker_dd.closest('.speaker_selection_container').find('.rationale-dropdown').show();
                } else {
                    speaker_dd.closest('.speaker_selection_container').find('.rationale_dd').removeClass("required is-invalid");
                    speaker_dd.closest('.speaker_selection_container').find('.rationale-dropdown').hide();
                }
                speaker_dd.closest('.speaker_selection_container').find('.rationale-dropdown').val('');
            }
        }
    }

    drawTopicDD(source = '') {
        this.topic_dd.empty();

        $.ajax({
            url     : 'api/meetingrequest/topics',
            method  : 'get',
            async   : false,
            data    : {
                meeting_date    : $('#meeting_date').val(),
                project_id      : $('#project_dd').val(),
                program_type_id : $('#program_type_dd').val(),
                hub_meeting_id  : $('#hub_meeting_id').val(),
                meeting_type_id : $('#meeting_type_dd').val()
            },
            success : (data) => {
                this.topics = data;

                if(Object.keys(this.topics).length !== 1) {
                    this.topic_dd.append("<option></option>");
                }

                let orderedTopics = Object.values(this.topics).sort((a,b) => a.topic_name.localeCompare(b.topic_name))
                for(let i in orderedTopics) {
                    let topic = orderedTopics[i];
                    this.topic_dd.append("<option value='" + topic.topic_id + "' data-show-length='" + topic.topic_length + "' data-show-description='" + topic.view_description_online + "'>" + topic.topic_name + "</option>");
                    this.topic_descriptions[topic.topic_id] = topic.description;
                }
            }
        })

        this.sub_topic_dd = this.dom.find('.sub_topic_dd');
        this.sub_topic_dd.select2();

        this.product_dd = this.dom.find('.product_dd');
        this.product_dd.off().select2();

        this.supplemental_material_dd = this.dom.find('.supplemental_material_dd');
        this.supplemental_material_dd.off().select2();

        this.topic_dd.parents().find("#topic_duration #topic_length").val(null);

        this.topic_dd.off().on('change', () => {
            let topic_length = this.topic_dd.find(':selected').data('show-length');
            if (topic_length == 0 || topic_length == undefined) {
                this.dom.find('#topic_length').val('');
            } else {
                this.dom.find('#topic_length').val(topic_length);
            }

            if (this.topic_length_attestation) {
                let isTopicLengthValid = parseInt(topic_length) > 0 && topic_length !== undefined;

                if (topic_length  <= this.attestation_config.limit || !isTopicLengthValid) {
                    this.topic_dd.parent().find("#topic_attestation_time").val('').attr("data-topic-id", "");
                }

                //Most of the scenarios when program_type_dd is changed,
                //we are refreshing the topics & clearing/hiding the speakerCard,
                //So excluding the checkTopicLength trigger on program_type_dd change
                if (isTopicLengthValid && !['program_type_dd'].includes(source)) {
                    this.dom.trigger($.Event('checkTopicLength', {slot: this}));
                }
            }

            if(this.topic_dd.find(':selected').data('show-description') == 1) {
                this.dom.find('#topic_description').empty().html(this.topic_descriptions[this.dom.find('.topic_dd').val()]);
            } else {
                this.dom.find('#topic_description').empty();
            }

            this.drawSpeakerDDs();
            this.triggerSlotFilledEvent();

            let topic_id = this.topic_dd.val();

            if(this.topics[topic_id].sub_topics.length) {
                this.dom.find('.sub_topic_container').show();
                this.sub_topic_dd.empty().append('<option>');

                for (let i in this.topics[topic_id].sub_topics) {
                    let sub_topic = this.topics[topic_id].sub_topics[i];
                    this.sub_topic_dd.append('<option value="' + sub_topic.sub_topic_id + '">' + sub_topic.sub_topic_name + '</option>')
                }

                if(this.topics[topic_id].sub_topics.length === 1) {
                    this.sub_topic_dd.find('option:not(:empty)').attr('selected', 'selected');
                }
            } else {
                this.dom.find('.sub_topic_container').hide();
                this.sub_topic_dd.empty()
            }

            let selectable_products = typeof (this.topics[topic_id]) !== 'undefined' && parseInt(this.topics[topic_id].selectable_products);

            if (selectable_products && this.topics[topic_id].products.length) {
                this.product_dd.empty().append('<option>');
                for (let i in this.topics[topic_id].products) {
                    let product = this.topics[topic_id].products[i];
                    this.product_dd.append('<option value="' + product.id + '">' + product.name + '</option>')
                }

                this.product_dd.select2({maximumSelectionLength: selectable_products});
                this.dom.find('.max_selectable_products').text('(min: 1, max: ' + selectable_products + ')');
                this.product_dd.addClass('required');
                app.pages.meetingrequest.formCards.speaker_card.requiredFields.xref_meeting_product = {'products[]' : 1}
                this.product_dd.closest('.product_dd_container').show();
            } else {
                this.product_dd.removeClass('required');
                this.product_dd.empty().closest('.product_dd_container').hide();
            }

            this.modular_slides_dd = this.dom.find('.modular_slides_dd');
            this.modular_slides_dd.select2();

            let is_modular = typeof (this.topics[topic_id]) !== 'undefined' && parseInt(this.topics[topic_id].is_modular);

            if(is_modular && this.topics[topic_id].modular_slides.length) {
                this.modular_slides_dd.empty().append('<option>');
                for(let i in this.topics[topic_id].modular_slides) {
                    let slide = this.topics[topic_id].modular_slides[i];
                    this.modular_slides_dd.append('<option value="' + slide.id + '">' + slide.name + '</option>')
                }

                this.modular_slides_dd.closest('.modular_slides_container').show();
            } else {
                this.modular_slides_dd.closest('.modular_slides_container').hide();
            }

            this.mandatory_materials = this.dom.find('.mandatory_supplemental_material');
            this.mandatory_materials.empty();
            this.supplemental_material_dd
                .removeClass('required is-invalid')
                .data('min', '')
                .data('max', '')
                .siblings('.max_selectable_supplemental').empty();
            if (typeof (this.topics[topic_id]) !== 'undefined' && this.topics[topic_id].supplemental_material.length
                && (
                    !app.appData.layout.meeting_request.check_is_modular ||
                    (app.appData.layout.meeting_request.check_is_modular && parseInt(this.topics[topic_id].is_modular))
                )
            ) {
                this.supplemental_material_dd.empty().append('<option>');
                let mandatory_flag = false;
                let topic_span = '';
                app.pages.meetingrequest.formCards.speaker_card.requiredFields.ref_topic = {'supplemental_material[]' : 0};

                if(parseInt(this.topics[topic_id].min_sub_material) > 0) {
                    app.pages.meetingrequest.formCards.speaker_card.requiredFields.ref_topic = {'supplemental_material[]' : 1};
                    this.supplemental_material_dd
                        .addClass('required is-invalid')
                        .data('min', this.topics[topic_id].min_sub_material)
                        .siblings('.max_selectable_supplemental').append('* min: '+this.topics[topic_id].min_sub_material);
                }

                if(parseInt(this.topics[topic_id].max_sub_material) > 0) {
                    this.supplemental_material_dd
                        .data('max', this.topics[topic_id].max_sub_material)
                        .select2({maximumSelectionLength: this.topics[topic_id].max_sub_material})
                        .siblings('.max_selectable_supplemental').append(', max: '+this.topics[topic_id].max_sub_material);
                }

                for (let i in this.topics[topic_id].supplemental_material) {
                    let topic = this.topics[topic_id].supplemental_material[i];
                    if(parseInt(topic.required) == 0 || !app.appData.layout.meeting_request.force_required) {
                        this.supplemental_material_dd.append('<option value="' + topic.topic_id + '">' + topic.topic_name_short + '</option>')
                    } else {
                        topic_span = topic.topic_name_short;
                        if(topic_span.length > 100) {
                            topic_span = '<span title="' + topic_span + '">' + topic_span.substring(0, 97) + '...</span>';
                        }
                        this.mandatory_materials.append('<br>' + topic_span);
                        mandatory_flag = true;
                    }
                }

                if (app.appData.layout.meeting_request.force_required && mandatory_flag) {
                    this.supplemental_material_dd.siblings('.mandatory_materials_container').show();
                }
                this.supplemental_material_dd.closest('.supplemental_materials_container').show();
            } else {
                this.supplemental_material_dd.closest('.supplemental_materials_container').hide();
                this.supplemental_material_dd.closest('.mandatory_materials_container').hide();
            }
        });

        // If 1 we autoselect the topic, and thus need to fetch speakers for it
        if(Object.keys(this.topics).length == 1) {
            this.topic_dd.trigger('change');
        }

        let attestedTopic = this.topic_dd.parent().find("#topic_attestation_time").attr("data-topic-id");

        if (this.topic_length_attestation && (Object.keys(this.topics).length !== 1 || attestedTopic !== this.topic_dd.val())) {
            this.topic_dd.parent().find("#topic_attestation_time").val(null).attr("data-topic-id", "");
            this.topic_dd.parent().find("#topic_duration #topic_length").val(null);
        }

        this.product_dd.on('change', () => this.triggerSlotFilledEvent());
        this.supplemental_material_dd.on('change', () => this.triggerSlotFilledEvent());
    }

    getSpeakers() {
        let speakers;
        let project_id = $('#project_dd').val();
        let event_type_id = app.appData.projects[project_id].event_type_id;
        let brand_id = app.appData.projects[project_id].brand_id;

        $.ajax({
            url     : 'api/meetingrequest/speakers',
            method  : 'get',
            async   : false,
            data    : {
                meeting_date    : $('#meeting_date').val(),
                project_id      : project_id,
                brand_id        : brand_id,
                program_type_id : $('#program_type_dd').val(),
                event_type_id   : event_type_id,
                topic_id        : this.dom.find('.topic_dd').val(),
                hub_meeting_id  : $('#hub_meeting_id').val()
            },
            success : data => { speakers = data }
        })

        return speakers;
    }

    getSpeakerDetails(speaker_id) {
        return $.ajax({
            url     : 'api/meetingrequest/speakers/'+speaker_id,
            data    : {
                project_id : $('#project_dd').val(),
                meeting_date    : $('#meeting_date').val(),
                brand_id: app.appData.projects[$('#project_dd').val()].brand_id,
                program_type_id: $('#program_type_dd').val(),
                include_program_group_type_id: app.appData.layout.meeting_request.speaker_brand_program_group_threshold_check
            },
            method  : 'get',
            async   : false
        })
    }

    drawSpeakerDD(speaker_dd, data, patients = false)
    {
        let project_id = $('#project_dd').val();
        let brand_id = app.appData.projects[project_id].brand_id;
        let local_speaker_rdt_size = app.appData.brands[brand_id]?.local_speaker_rdt_size;
        let show_speakers_by_consultant_category = app.appData.layout.meeting_request.show_speakers_by_consultant_category;

        if(!patients && typeof local_speaker_rdt_size !== 'undefined' && local_speaker_rdt_size > 0)
        {
            speaker_dd.empty().append($('<option>'));

            let optgroup_local = $('<optgroup label="Regional">');
            let optgroup_non_local = $('<optgroup label="Non-Local">');

            for(let i in data) {
                let speaker = data[i];

                if(!patients && speaker.consultant_code == 'patient' || patients && speaker.consultant_code != 'patient') {
                    continue;
                }

                if(speaker.rdt != null) {
                    let rdts = speaker.rdt.split(',');
                    let local = false;

                    $.each(rdts, (key, value) => {
                        local = local || value.substring(0, local_speaker_rdt_size) == app.appData.curPosition.rdt.substring(0, local_speaker_rdt_size);
                    });

                    let option = $("<option value='" + speaker.speaker_id + "'>" + speaker.name + " - " + speaker.city + ", " + speaker.state + "</option>");

                    if(local) {
                        optgroup_local.append(option)
                    } else {
                        optgroup_non_local.append(option)
                    }
                }
            }

            if(optgroup_local.children().length == 0) {
                optgroup_local.append($('<option disabled>none</option>'))
            }

            if(optgroup_non_local.children().length == 0) {
                optgroup_non_local.append($('<option disabled>none</option>'))
            }

            speaker_dd.append(optgroup_local);
            speaker_dd.append(optgroup_non_local);

        } else if(show_speakers_by_consultant_category)
        {
            speaker_dd.empty().append($('<option>'));
            let consultant_category_types = {};
            let otherOptgroup = null;

            for (let i in data) {
                let speaker = data[i];
                let option = $("<option value='" + speaker.speaker_id + "'>" + speaker.name + " - " + speaker.city + ", " + speaker.state + "</option>");

                if (!speaker.consultant_category_type) {
                    if (!otherOptgroup) {
                        otherOptgroup = $('<optgroup label="OTHER">');
                    }
                    otherOptgroup.append(option);
                } else {
                    if (!Object.prototype.hasOwnProperty.call(consultant_category_types, speaker.consultant_category_type)) {
                        consultant_category_types[speaker.consultant_category_type] = $('<optgroup label="' + speaker.consultant_category_type + '">');
                    }
                    consultant_category_types[speaker.consultant_category_type].append(option);
                }
            }

            for (let key in consultant_category_types) {
                speaker_dd.append(consultant_category_types[key]);
            }

            if (otherOptgroup) {
                speaker_dd.append(otherOptgroup);
            }
        } else {
            speaker_dd.empty().append("<option></option>")

            for(let i in data) {
                let speaker = data[i];
                let disabled = '';
                let suffix = '';
                if(!patients && speaker.consultant_code == 'patient' || patients && speaker.consultant_code != 'patient') {
                    continue;
                }

                let classDanger = '';
                if(app.appData.layout.meeting_request.speaker_brand_program_group_threshold_check && (speaker.speaker_status_key == "apprv_cap" || this.isThresholdMet(speaker)))
                {
                    classDanger = 'text-danger';
                }

                if(speaker.topic_cap_max_met && speaker.consultant_code == 'hcp')
                {
                    disabled = 'disabled';
                    suffix = " - Topic Capped"
                    classDanger = 'text-danger';
                }

                speaker_dd.append("<option class='" + classDanger + "' " + disabled + " value='" + speaker.speaker_id + "'>"
                    + speaker.name + " - " + speaker.city + ", " + speaker.state + suffix + "</option>")
            }
        }

        speaker_dd.select2({
            templateResult: function (data, container) {
                if (data.element) {
                    $(container).addClass($(data.element).attr("class"));
                }
                return data.text;
            }
        });
        this.dom.trigger('SpeakerDDRedraw');
    }

    drawSpeakerLocDD() {
        let speakerlocation;
        let locDD = this.dom.find('.speaker_location_dd');
        let meetingType = $('#meeting_type_dd').val();
        $.ajax({
            url : 'api/meetingrequest/speakerlocation',
            method  :'get',
            async   : false,
            data    : {meeting_type_id : meetingType},
            success : data => { speakerlocation = data }
        });

        locDD.empty().append($('<option>'));

        for(let i in speakerlocation) {
            let location = speakerlocation[i]
            let option = $("<option value='" + location.speaker_loc_id + "'>" + location.speaker_loc_name + "</option>");

            locDD.append(option);
        }
    }

    drawSpeakerDDs() {
        this.dom.find('.speaker_dd, .patient_speaker_dd').hide();
        let speakers = this.getSpeakers();

        let dds = this.dom.find('.speaker_dd');
        dds.each((i, dd) => this.drawSpeakerDD($(dd), speakers))

        let patient_dds = this.dom.find('.patient_speaker_dd');
        patient_dds.each((i, dd) => this.drawSpeakerDD($(dd), speakers, true))

        this.dom.find('.speaker_dd, .patient_speaker_dd').show();
        this.drawSpeakerLocDD();
    }

    triggerSlotFilledEvent() {
        this.dom.trigger('SlotFillUpdate');
    }

    getData() {
        let alternate_speaker_ids = [];
        let alternate_speaker_rationale = {};
        let alternate_speaker_names = {};
        let travel_details = [];
        let mtm_details = [];
        this.dom.find('.speaker_dd:gt(0)').each((i, e) => {
            alternate_speaker_ids.push($(e).val());
            if (this.show_non_local_speaker_rationale && $(e).val() !== '') {
                alternate_speaker_rationale[$(e).val()] = $(e).closest('.speaker_selection_container').find('.rationale_dd').val();
                alternate_speaker_names[$(e).val()] = $(e).find('option:selected').text();
            }
        })
        this.dom.find('.travel_details').each((i, e) => {
            travel_details.push({
                'need_travel_arrangements': $(e).find('.need_travel_arrangements').is(':checked') ? 1 : 0,
                'need_ground': $(e).find('.need_ground').is(':checked') ? 'on' : '',
                'need_air': $(e).find('.need_air').is(':checked') ? 'on' : '',
                'need_hotel': $(e).find('.need_hotel').is(':checked') ? 'on' : ''
            });
        })
        let speaker_loc = this.dom.find('.speaker_location_dd').val();
        this.dom.find('.rec_mtm').each((i, e) => {
            mtm_details.push($(e).val());
        })

        let alternate_patient_speaker_ids = [];
        let alternate_patient_speaker_rationale = {};
        let alternate_patient_speaker_names = {};
        this.dom.find('.patient_speaker_dd:gt(0)').each((i, e) => {
            alternate_patient_speaker_ids.push($(e).val());
            if (this.show_non_local_speaker_rationale && $(e).val() !== '') {
                alternate_patient_speaker_rationale[$(e).val()] = $(e).closest('.speaker_selection_container').find('.rationale_dd').val();
                alternate_patient_speaker_names[$(e).val()] = $(e).find('option:selected').text();
            }
        })

        let primary_speaker_id = this.dom.find('.speaker_dd:first').val();
        let local_cap_upper_bound = 0;
        let local_cap_count = 0;
        let speaker_local_cap = 0;
        let speaker_contract = {};

        if(typeof this.speakerData[primary_speaker_id] != "undefined" && !$.isEmptyObject(this.speakerData[primary_speaker_id])) {
            local_cap_upper_bound = parseInt(this.speakerData[primary_speaker_id].local_cap.localcap);
            local_cap_count = parseInt(this.speakerData[primary_speaker_id].local_cap.count);

            speaker_local_cap = !(local_cap_upper_bound == 0 || local_cap_count < local_cap_upper_bound) ? 1 : 0;
            speaker_contract = this.speakerData[primary_speaker_id].contracted;
        }

        return {
            topic_id    : this.dom.find('.topic_dd').val(),
            topic_attestation_timestamp: this.topic_length_attestation ? this.dom.find('#topic_attestation_time').val() : "",
            sub_topic_id: this.dom.find('.sub_topic_dd').val(),
            supplemental: this.dom.find('.supplemental_material_dd').val(),
            product_id  : this.dom.find('.product_dd').val(),
            speaker_id  : this.dom.find('.speaker_dd:first').val(),
            speaker_local_cap   : speaker_local_cap,
            patient_speaker_id  : this.dom.find('.patient_speaker_dd:first').val(),
            speaker_name        : this.dom.find('.speaker_dd:first :selected').text(),
            rationale: this.show_non_local_speaker_rationale ? this.dom.find('.rationale_dd:first').val() : "",
            travel_details : travel_details,
            speaker_loc : speaker_loc,
            contact_made    : this.dom.find('.contact_made').is(':checked') ? 1 : 0,
            alternate_speaker_ids   : alternate_speaker_ids,
            alternate_speaker_names : alternate_speaker_names,
            alternate_speaker_rationale   : alternate_speaker_rationale,
            alternate_patient_speaker_ids   : alternate_patient_speaker_ids,
            alternate_patient_speaker_names : alternate_patient_speaker_names,
            alternate_patient_speaker_rationale   : alternate_patient_speaker_rationale,
            address            : this.dom.find('.address').val(),
            mtm_details   : mtm_details,
            contracted    : speaker_contract,
            speaker_confirmed  : this.dom.find('.speaker_confirmed').val(),
        }
    }

    reassignSpeakerPreferenceRanks(patients = false) {
        let rank = 0;
        let css_class = patients ? '.patient_speaker_preference' : '.speaker_preference';

        this.dom.find(css_class).each((index, element) => {
            rank++;
            $(element).text(rank);
        })
    }

    addAlternateSpeakerDD(speaker_preference, is_required, is_patient, selections_container_class){
        let alternate_speaker = $(twig({data: speaker_selection_container_template}).render({
            priority:  speaker_preference == 'normal' ? 'Alternate' : '',
            speaker_preference: speaker_preference,
            required: is_required ? '*' : '',
            invalid_class: is_required ? 'required is-invalid' : '',
            patient: is_patient,
            delete: !(is_required) ? '<span class="badge badge-warning remove_speaker_button" role="button">Remove</span>': '',
            show_speaker_bio: false,
            show_speaker_travel_contact: app.appData.layout.meeting_request.show_speaker_travel_contact,
            labels : app.appData.labels,
            show_non_local_speaker_rationale : this.show_non_local_speaker_rationale
        }));

        let speaker_dd_type = is_patient ? 'patient_speaker_dd' : 'speaker_dd';

        let speakers = this.getSpeakers();
        this.drawSpeakerDD($(alternate_speaker.find('.' + speaker_dd_type)[0]), speakers, is_patient);
        let altSpeakerRationale = this.dom.find('.' + selections_container_class).append(alternate_speaker);
        if (this.show_non_local_speaker_rationale) {
            let rationale_dropdown = altSpeakerRationale[0].lastChild.children[1];
            this.modal = rationale_dropdown.querySelector('select');
            $(this.modal).select2();
            $(rationale_dropdown).hide();
        }

        if(!is_required){
            alternate_speaker.find('.remove_speaker_button').on('click', () => {
                alternate_speaker.remove();
                this.dom.trigger('SlotFillUpdate');
                this.dom.trigger('UpdateSpeakerSideCards');
                this.reassignSpeakerPreferenceRanks();
                this.localCapValidation();
            })
        }

        this.reassignSpeakerPreferenceRanks();
    }
}
