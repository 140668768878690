import { Page } from '../page.js';
import '../../app.js';

import './SpeakerSlot';
import {AdvertisingCard} from "./AdvertisingCard";
import {AttendeesCard} from "./AttendeesCard";
import {AVOptionsCard} from "./AVOptionsCard";
import {BudgetCard} from "./BudgetCard";
import {ConsultationCard} from "./ConsultationCard";
import {DocusignAttestationCard} from "./DocusignAttestationCard";
import {EdifyCard} from "./EdifyCard";
import {FedexCard} from "./FedexCard";
import {MeetingDateCard} from "./MeetingDateCard";
import {NotesCard} from "./NotesCard";
import {ProjectCard} from "./ProjectCard";
import {SpeakerCard} from "./SpeakerCard";
import {SpeakerSideCard} from "./SpeakerSideCard";
import {SpokeCard} from "./SpokeCard";
import {SubmitRequestCard} from "./SubmitRequestCard";
import {SupplyItemsCard} from "./SupplyItemsCard";
import {TourCard} from "./TourCard";
import {VenueCard} from "./VenueCard";
import {HubCard} from "./HubCard";
import {SLTQuestionsCard} from "./SLTQuestionsCard";
import {ExternalProgramsCard} from "./ExternalProgramsCard";
import {AttestationCard} from "./AttestationCard";
import {SponsorshipCard} from "./SponsorshipCard";
import {BusinessJustificationCard} from "./BusinessJustificationCard";
import {ManualSpeakerCard} from "./ManualSpeakerCard";
import {FacultyPrepCallCard} from "./FacultyPrepCallCard";
import {SLTFormEmailCard} from "./SLTFormEmailCard";
import {UploadMirfCard} from "./UploadMirfCard";
import {StrategicBudgetCard} from "./StrategicBudgetCard";
import {CongressCard} from "./CongressCard";
import {OceCartQuestionsCard } from './OceCartQuestionsCard';

var meeting_request_template = require('./meetingrequest.twig');

export class MeetingRequest extends Page {
    constructor() {
        super();
        this.dom = $(meeting_request_template);
        this.applyingHub = false;
        this.expenseUpdateEnabled = true;
        this.postLoadCardsCallbacks = [];
        this.nta_runonce = [];
        this.speaker_side_cards = [];

        //[... formcards] is used to copy array by value not reference
        this.layoutCards = [...app.appData.layout.meeting_request.formcards];

        this.formCards = {
            project_card: new ProjectCard(),
            strategic_budget_card: new StrategicBudgetCard(),
            tour_card: new TourCard(),
            spoke_card: new SpokeCard(),
            meeting_date_card: new MeetingDateCard(),
            slt_questions_card: new SLTQuestionsCard(),
            edify_card: new EdifyCard(),
            speaker_card: new SpeakerCard(),
            venue_card: new VenueCard(),
            hub_card: new HubCard(),
            attendees_card: new AttendeesCard(),
            oce_cart_questions_card: new OceCartQuestionsCard(),
            av_options_card: new AVOptionsCard(),
            supply_items_card: new SupplyItemsCard(),
            fedex_card: new FedexCard(),
            notes_card: new NotesCard(),
            consultation_card: new ConsultationCard(),
            docusign_attestation_card: new DocusignAttestationCard(),
            advertising_card: new AdvertisingCard(),
            submit_request_card: new SubmitRequestCard(),
            attestation_card: new AttestationCard(),
            sponsorship_card: new SponsorshipCard(),
            business_justification_card: new BusinessJustificationCard(),
            manual_speaker_card: new ManualSpeakerCard(),
            faculty_prep_call_card: new FacultyPrepCallCard(),
            slt_form_email_card: new SLTFormEmailCard(),
            upload_mirf_card: new UploadMirfCard(),
            congress_card: new CongressCard()
        };

        this.budget_card = new BudgetCard();
        this.external_programs_card = new ExternalProgramsCard();

        this.loadCards();
        this.formCards.project_card.dom.show();

        this.dom.find('#side_column_container').append(this.budget_card.dom);
        this.dom.find('#side_column_container').append(this.external_programs_card.dom);

        this.dom.on('CardFilledOut', (event) => {
            let next = event.target.nextElementSibling;
            if(next)
            {
                if(this.formCards[event.target.id].canShowNextCard()) {
                    this.formCards[next.id].show();
                    if($(event.target).is(':visible'))
                    {
                        this.dom.trigger('NeedBudgetUpdate');
                    }
                }
                else {
                    this.formCards[next.id].clearAndHide();
                }
            }
        })

        this.dom.on('CardNotFilledOut', (event) => {
            let next = event.target.nextElementSibling;
            if(next) {
                this.formCards[next.id].clearAndHide()
            }
        })

        this.dom.on('ProjectUpdated', () => {
            this.getRequiredFields().then(() => {
                this.formCards.project_card.cardFilledOutTriggers();
            })

            if(typeof this.formCards.attendees_card.requiredFields.mf_meeting == 'undefined')
            {
                this.formCards.attendees_card.requiredFields.mf_meeting = {};
            }

            if(this.formCards.project_card.project_id && app.appData.projects[this.formCards.project_card.project_id].show_program_documents_shipping_option) {
                this.formCards.attendees_card.dom.find('#send_rep_prog_documents_container').show();
                this.formCards.attendees_card.requiredFields.mf_meeting.send_rep_prog_documents = 1;
            } else {
                this.formCards.attendees_card.dom.find('#send_rep_prog_documents_container').hide();
                this.formCards.attendees_card.requiredFields.mf_meeting.send_rep_prog_documents = 0;
            }

            this.external_programs_card.displayData(0, 0, "");
        });

        this.dom.on('NeedBudgetUpdate', () => {
            if(!this.expenseUpdateEnabled) {
                return;
            }

            this.calculateDistance().then(() => {
                this.budget_card.update(this.getRequestData());
            })
        })

        this.dom.on('RefreshStrategicBudget', () => {
            this.formCards.strategic_budget_card.reset();
            if(this.formCards.strategic_budget_card.checkConfig())
            {
                this.formCards.strategic_budget_card.update(this.getRequestData());
            }
        })

        this.dom.on('UpdateSpeakerSideCards', () => {
            $('.speaker_side_card').remove();
            this.speaker_side_cards = [];

            let speakerData = this.getSpeakerData();

            let project_id = $('#project_dd').val();
            let brand_id = app.appData.projects[project_id].brand_id;

            for(let i in speakerData)
            {
                let sidecard;
                let speaker_ids = [];
                let topic_id = speakerData[i].topic_id;
                let topic_name = app.appData.topics[
                    app.appData.topics.findIndex(t => t.topic_id == topic_id)
                ].topic_name ?? '';

                sidecard = new SpeakerSideCard(topic_name);
                this.speaker_side_cards.push(sidecard);
                this.dom.find('#side_column_container').append(sidecard.dom);
                this.bindSideCardEvents();

                speaker_ids.push(speakerData[i].speaker_id);

                if(speakerData[i].alternate_speaker_ids.length > 0) {
                    speaker_ids.push(...speakerData[i].alternate_speaker_ids);
                }

                if(speakerData[i].patient_speaker_id) {
                    speaker_ids.push(speakerData[i].patient_speaker_id);
                }

                if(speakerData[i].alternate_patient_speaker_ids.length > 0) {
                    speaker_ids.push(...speakerData[i].alternate_patient_speaker_ids);
                }

                // Remove all potentially blank select options
                speaker_ids = speaker_ids.filter(n=>n);

                sidecard.updateSpeakers(speaker_ids, topic_id, project_id, brand_id);
            }
        });

        this.dom.on('RefreshRequiredFields', (event, callback) => {
            this.getRequiredFields().then(() => {
                if(callback) {
                    callback();
                }
            });
        })

        this.dom.on('SetDefaultTime', (event,default_time) => {
            this.formCards.meeting_date_card.setDefaultTime(default_time);
        })
        this.dom.on('SetDefaultTimeZone', (event,default_timezone) => {
            this.formCards.meeting_date_card.setDefaultTimeZone(default_timezone);
        })

        this.dom.on('DrawMeetingDates', () => {
            this.formCards.meeting_date_card.drawMeetingDates();
        })

        this.dom.on('DrawVenueTypes', () => {
            this.formCards.venue_card.drawVenueTypeDDs();
        })

        this.dom.on('RefreshTopics', (event, triggerSource = '') => {
            Object.entries(this.formCards.speaker_card.speaker_slots).forEach(entry => {
                let slot = entry[1];
                slot.drawTopicDD(triggerSource);
                slot.triggerSlotFilledEvent();
            });
        })

        this.dom.on('SpokeMeetingSelected', () => {
            this.formCards.spoke_card.show()
        });

        this.dom.on('NonSpokeMeetingSelected', () => {
            this.dom.find('[name=meeting_date], [name=end_time_hr], [name=start_time_hr], [name=timezone_id]').removeAttr('disabled')

            // Unlock topics and speakers
            this.dom.find('.speaker_dd').removeAttr('disabled').select2({disabled: false})
            this.dom.find('.topic_dd').removeAttr('disabled')

            //Show spoke-inapplicable fields
            this.dom.find('.alt-date').show()
            this.dom.find('.add_alt_speaker').show()
            this.dom.find('.add_alt_patient_speaker').show()
        });

        this.dom.on('HideSpokeCard', () => { this.formCards.spoke_card.clearAndHide() });

        this.dom.on('HubApplied', () => {
            this.formCards.meeting_date_card.filled_out = false;
            this.formCards.meeting_date_card.cardFilledOutTriggers();

            let hubId = $('#hub_meeting_id').val();
            $.ajax({
                url: "api/getMeetingData",
                data: {
                    meeting_id: hubId
                },
                method: 'post'
            }).then((data) => {
                this.applyingHub = hubId;
                this.expenseUpdateEnabled = false;
                this.dom.find('[name=meeting_date], [name=end_time_hr], [name=start_time_hr], [name=timezone_id]').removeAttr('disabled')

                // Unlock topics and speakers
                this.dom.find('.speaker_dd').removeAttr('disabled').select2({disabled: false})
                this.dom.find('.patient_speaker_dd').attr('disabled', 'disabled').select2({disabled: false})
                this.dom.find('.topic_dd').removeAttr('disabled')

                // Fill out meeting date card
                $('#meeting_date').val(moment(data.logistics_info.meeting_date).format('MM/DD/YYYY'))
                $('#time_dd').val(data.logistics_info.start_time_hr + ':' + data.logistics_info.start_time_min + ':' + data.logistics_info.start_time_ampm.toLowerCase());
                if(data.logistics_info.end_time_hr != '0' && data.logistics_info.end_time_hr != '') {
                    $('#end_time_dd').val(data.logistics_info.end_time_hr + ':' + data.logistics_info.end_time_min + ':' + data.logistics_info.end_time_ampm.toLowerCase());
                    this.dom.find('[name=end_time_hr]').attr('disabled', 'disabled');
                }
                $('#timezone_dd').val(data.logistics_info.timezone_id);
                this.dom.find('.alt-date').hide();

                this.formCards.meeting_date_card.cardFilledOutTriggers();

                // Clear speaker slots
                Object.entries(this.formCards.speaker_card.speaker_slots).forEach(entry => {
                    let slot = entry[1];
                    slot.dom.detach();
                })

                this.formCards.speaker_card.speaker_slots = [];

                // Fill topics
                for(let i in data.speaker_info) {
                    let speaker = data.speaker_info[i];

                    if(speaker.patient_speaker == '0') {
                        this.formCards.speaker_card.addTopic();

                        let slot_index = this.formCards.speaker_card.speaker_slots.length - 1;
                        let speaker_slot = this.formCards.speaker_card.speaker_slots[slot_index];

                        speaker_slot.dom.find('.topic_dd').val(speaker.topic_id).trigger('change');
                    }
                }

                // Fill speakers
                let slot_index = 0;
                for(let i in data.speaker_info) {
                    let speaker = data.speaker_info[i];
                    let speaker_slot;

                    if(speaker.patient_speaker == '1') {
                        speaker_slot = this.formCards.speaker_card.speaker_slots[slot_index - 1];

                        if(typeof speaker_slot != 'undefined')
                            speaker_slot.dom.find('.patient_speaker_dd').val(speaker.speaker_id).select2();
                    }
                    else {
                        speaker_slot = this.formCards.speaker_card.speaker_slots[slot_index];

                        if(typeof speaker_slot != 'undefined')
                        {
                            let speaker_dd = speaker_slot.dom.find('.speaker_dd');
                            speaker_dd.val(speaker.speaker_id).select2();
                            speaker_dd.trigger('change');

                            let speaker_loc_dd = speaker_slot.dom.find('.speaker_location_dd');
                            speaker_slot.dom.find('.speaker_confirmed').val(speaker.speaker_confirmed);
                            speaker_loc_dd.val(speaker.speaker_loc).select2();
                            speaker_loc_dd.trigger('change');
                        }

                        slot_index++;
                    }
                }

                this.dom.find('[name=meeting_date], [name=start_time_hr], [name=timezone_id]').attr('disabled', 'disabled');
                this.dom.find('.add_alt_speaker').hide();
                this.dom.find('.add_alt_patient_speaker').hide();

                // Lock topics and speakers
                this.dom.find('.speaker_dd').attr('disabled', 'disabled').select2({disabled: true})
                this.dom.find('.patient_speaker_dd').attr('disabled', 'disabled').select2({disabled: true})
                this.dom.find('.topic_dd').attr('disabled', 'disabled')
                this.dom.find('.speaker_location_dd').attr('disabled', 'disabled').select2({disabled: true})

                this.formCards.speaker_card.cardFilledOutTriggers();
                this.expenseUpdateEnabled = true;
            });
        })

        this.dom.on('EnableAV', () => {
            let formCards = app.appData.layout.meeting_request.formcards;
            this.dom.find('#' + formCards[formCards.indexOf('av_options_card') - 1]).after(
                this.formCards.av_options_card.dom
            );
        });
        this.dom.on('DisableAV', () => {
            this.formCards.av_options_card.clearAndHide();
            this.formCards.av_options_card.dom.detach();
        });

        this.dom.on('EnableCongress', () => {
            let formCards = app.appData.layout.meeting_request.formcards;
            this.dom.find('#' + formCards[formCards.indexOf('congress_card') - 1]).after(
                this.formCards.congress_card.dom
            );

            this.setSpecialProjectIdOnPseudoFieldClick();
        });

        this.dom.on('DisableCongress', () => {
            this.formCards.congress_card.clearAndHide();
            this.formCards.congress_card.dom.detach();
        });

        this.dom.on('ClearSpeakerSideCard', () => {
            for(let i in this.speaker_side_cards){
                this.speaker_side_cards[i].empty();
            }
        });

        this.dom.on('HideSpeakerSideCard', () => {
            this.dom.find('.speaker_side_card').hide();
        });

        this.dom.on('ClearVenueCard', () => {
            this.formCards['attendees_card'].clearAndHide();
            this.formCards['venue_card'].clear();
            this.formCards['venue_card'].cardFilledOutTriggers();
        });

        this.formCards.speaker_card.dom.on('change', '.speaker_dd, .patient_speaker_dd', () => {
            if(app.isMobile()){
                $(".speaker_overlay").show();
            }

            this.dom.trigger('UpdatePrep');
        })

        this.dom.on('ChangeMeetingDate', () => {
            let project_id = $('#project_dd').val();
            let program_type_id = $('#program_type_dd').val();
            let meeting_date = $('#meeting_date').val();

            this.external_programs_card.displayData(project_id, program_type_id, meeting_date);
        })

        this.dom.on('UpdatePrep', () => {
            if(this.formCards.speaker_card.isFilledOut()) {
                let speakerData = this.getSpeakerData();
                this.formCards.faculty_prep_call_card.renderFacultyPrepQuestions(speakerData);
            }
        })

        this.dom.on('triggerTopicLength', () => {
            this.formCards.speaker_card.triggerTopiclength();
        });

        this.dom.find('#submit_request').on('click', () => this.requestProgram());
    }

    setSpecialProjectIdOnPseudoFieldClick(){
        let congress_id = 0;
        let special_projects = app.appData.special_project;

        $.each(special_projects, function( index, special_project) {
            $.each(special_project, function (key, value) {
                if(key === "spec_proj_name" && value === "Congress"){
                    congress_id = special_project.spec_proj_id;
                    return true;
                }
            });
        });

        this.dom.on('change', '#pseudo_spec_proj_id', () => {
            $('#spec_proj_id').val('');

            if($("#pseudo_spec_proj_id").is(":checked")) {
                $('#spec_proj_id').val(congress_id);
            }
        });
    }

    getSpeakerData() {
        let data = [];
        Object.entries(this.formCards.speaker_card.speaker_slots).forEach(entry => {
            let slot = entry[1];
            data.push(slot.getData());
        });

        return data;
    }

    loadCards(currentCards, excludeCards) {
        //[... formcards] is used to copy array by value not reference
        currentCards =
            (typeof currentCards !== 'undefined' && currentCards.length)
                ? currentCards : [...app.appData.layout.meeting_request.formcards];

        if(typeof excludeCards == 'undefined') {
            excludeCards = [];
        }

        for(let i = 0; i < this.layoutCards.length; i++) {
            let cardName = this.layoutCards[i];

            if(excludeCards.indexOf(cardName) == -1) {
                this.formCards[cardName].clear();
                this.formCards[cardName].dom.hide().detach();
            }
        }

        for(let j = 0; j < currentCards.length; j++) {
            let cardName = currentCards[j];

            if(excludeCards.indexOf(cardName) == -1
            && currentCards.indexOf(cardName) >= 0
            && typeof this.formCards[cardName] !== 'undefined') {
                this.dom.find('#main_card_column').append(this.formCards[cardName].dom);
                this.layoutCards.push(cardName);
            }
        }

        this.layoutCards = [...currentCards];
    }

    getRequestData() {
        let av_options = this.formCards.av_options_card.getAvOptions();
        let project_id = this.formCards.project_card.project_dd.val();
        let event_type_id;
        let rationale_id = null;
        let short_lead = this.formCards.meeting_date_card.short_lead_time && !app.appData.layout.meeting_request.delay_short_lead;

        if(this.formCards.project_card.event_type_dd.val())
        {
            event_type_id = this.formCards.project_card.event_type_dd.val();
        }
        else if(project_id) {
            event_type_id = app.appData.projects[project_id].event_type_id;
        }

        if(this.formCards.project_card.rationale_type_dd.val()){
            rationale_id = this.formCards.project_card.rationale_type_dd.val();
        }

        let speakerData = this.getSpeakerData();
        let speakerLocalCap = 0;

        for(let i in speakerData) {
            speakerLocalCap += speakerData[i].speaker_local_cap;
        }

        let ret = {
            project_id                      : project_id,
            program_type_id                 : this.formCards.project_card.program_type_dd.val(),
            program_type_option_id          : this.formCards.project_card.program_type_option_dd.val(),
            meeting_type_id                 : this.formCards.project_card.meeting_type_id,
            service_id                      : this.formCards.project_card.service_type_dd.val(),
            event_type_id                   : event_type_id,

            account_name                    : this.formCards.project_card.account_name.val(),

            is_tour                         : this.formCards.tour_card.is_tour_dd.val(),
            tour_id                         : this.formCards.tour_card.tour_dd.val(),

            meeting_date                    : $('#meeting_date').val(),
            meeting_date2                   : $('#meeting_date2').val(),
            meeting_date3                   : $('#meeting_date3').val(),
            end_date                        : $('#end_date').val(),
            all_day_meeting                 : $("#all_day_meeting").is(":checked") ? 1 : 0,
            meeting_time                    : this.formCards.meeting_date_card.time_dd.val(),
            meeting_arrival_time            : this.formCards.meeting_date_card.arrival_time_dd ? this.formCards.meeting_date_card.arrival_time_dd.val() : '',
            meeting_end_time                : this.formCards.meeting_date_card.end_time_dd ? this.formCards.meeting_date_card.end_time_dd.val() : '',
            meeting_timezone                : this.formCards.meeting_date_card.timezone_dd.val(),
            short_lead_time                 : short_lead ? 1 : 0,
            slt_question_ans                : this.formCards.slt_questions_card.getAnswers(),
            send_to_edify                   : this.formCards.edify_card.getSelection(),
            oce_cart_questions_data         : this.formCards.oce_cart_questions_card.getdata(),

            speakers                        : speakerData,
            speaker_local_cap               : speakerLocalCap,
            venues                          : this.formCards.venue_card.getVenueData(),
            fedex                           : this.formCards.fedex_card.getData(),
            food_beverage                   : $('#food_beverage').val(),

            est_att                         : $('#estimated_attendees').val(),
            is_cannula_shipment_requested   : parseInt($('input[name="is_cannula_shipment_requested"]:checked').val()),
            est_patients                    : parseInt($('#est_patients').val()),
            max_att                         : $('#maximum_attendees').val(),
            minimum_att_rationale           : $('#minimum_att_rationale').val(),
            maximum_att_rationale           : $('#maximum_att_rationale').val(),
            rep_notes                       : $('#rep_notes').val(),
            injector_experience_id          : $('#injector_experience_id').val(),
            invite_fed_emp                  : $('#invite_fed_emp').val(),
            invite_state_emp                : $('#invite_state_emp').val(),

            needs_av                        : av_options.needs_av,
            av_options                      : av_options.checked,
            supply_item                     : this.formCards.supply_items_card.getSupplyItems(),
            send_rep_prog_documents         : $('#send_rep_prog_documents').val(),

            is_spoke                        : this.formCards.hub_card.isSpoke() ? 1 : 0,
            is_hub                          : this.formCards.hub_card.isHub() ? 1 : 0,
            hub_meeting_id                  : this.formCards.spoke_card.dom.find('#hub_meeting_id').val(),
            max_spoke_programs              : this.formCards.hub_card.dom.find('#max_spoke_programs').val(),

            creator                         : app.appData.curPosition.wwid,
            rdt                             : this.formCards.strategic_budget_card.budget_available.data('rdt') ? this.formCards.strategic_budget_card.budget_available.data('rdt') : app.appData.curPosition.rdt,
            company_code                    : app.appData.curPosition.company_code,
            selling_division_code           : app.appData.curPosition.selling_division_code,
            se_session                      : $('#se_session').val(),

            consultation_reason             : $('#consultation_reason').val(),
            docusign_attestation_needed     : $('#docusign_attestation_needed').val(),
            rationale_id                    : rationale_id,

            business_justification_note     : $('#business_justification_note').val(),
            manual_speaker_data             : this.formCards.manual_speaker_card.getData(),

            faculty_speaker_data            : this.formCards.faculty_prep_call_card.getSpeakerOptions(speakerData),

            budget_rdt                      : this.formCards.strategic_budget_card.budget_available.data('budget-rdt') ? this.formCards.strategic_budget_card.budget_available.data('budget-rdt') : '',
            budget_position_id              : this.formCards.strategic_budget_card.budget_available.data('position-id') ? this.formCards.strategic_budget_card.budget_available.data('position-id') : '',

            spec_proj_id                    : $('#spec_proj_id').val(),
            congress_name                   : $('#congress_name').val(),

            audience_type: this.formCards.attendees_card.dom.find("#audience_type").val(),
            audience_type_other: this.formCards.attendees_card.dom.find("#audience_type_other").val()
        }

        return { ...ret, ...this.formCards.advertising_card.getData(), ... this.formCards.sponsorship_card.getData()};
    }

    getProjectProgramTypeConfig(){
        let project_id = this.dom.find('#project_dd').val();
        let program_type_id = this.dom.find('#program_type_dd').val();
        if(project_id && program_type_id && app.appData.projects[project_id].program_types[program_type_id]){
            return app.appData.projects[project_id].program_types[program_type_id].configuration;
        }

        return false;
    }

    getRequiredFields() {
        return $.ajax({
            url: 'api/meetingrequest/requiredfields',
            data: this.getRequestData()
        }).then((data) => {
            this.requiredFields = data.requiredfields;
            let excludedCards = ['project_card', 'topic_module_card'];
            let proj_prog_config = this.getProjectProgramTypeConfig();
            if(proj_prog_config){
                if(proj_prog_config.sb.meeting_request.manual_service_type_selection == true) {
                    this.requiredFields.mf_meeting['service_type_id'] = 1;
                }

                if(proj_prog_config.sb.meeting_request.show_cannula_request == true) {
                    this.requiredFields.mf_meeting['is_cannula_shipment_requested'] = 1;
                }

                if(proj_prog_config.sb.meeting_request.show_estimated_patients == true) {
                    this.requiredFields.mf_meeting['est_patients'] = 1;
                }

                if(proj_prog_config.sb.meeting_request.venue_required == false) {
                    for(let i in this.requiredFields.mf_venue) {
                        this.requiredFields.mf_venue[i] = 0;
                    }
                }

                if(proj_prog_config.sb.meeting_request.venue_phone_required == true) {
                    this.requiredFields.mf_venue.phone = 1;
                }

                if(!proj_prog_config.sb.meeting_request.display_faculty_prep_section) {
                    this.requiredFields.faculty_prep_call_card = 0;
                    excludedCards.push('faculty_prep_call_card');
                }

                if((proj_prog_config.sb.meeting_request.show_av ?? true) == false) {
                    excludedCards.push('av_options_card');
                }
            }

            this.loadCards(data.formcards, excludedCards);

            if(this.postLoadCardsCallbacks.length > 0){
                for(let i = 0; i < this.postLoadCardsCallbacks.length; i++){
                    this.postLoadCardsCallbacks[i]();
                }
                this.postLoadCardsCallbacks = [];
            }

            this.show_event_type_dd = typeof app.appData.layout.meeting_request.show_event_type_dd != 'undefined' && app.appData.layout.meeting_request.show_event_type_dd;
            if(    $('#project_dd').val()
                && app.appData.projects[$('#project_dd').val()].event_type_id == 0
                && this.show_event_type_dd) {
                this.requiredFields.mf_meeting.event_type_id = 1;
            }

            let default_show_rationale_question = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_rationale_question;
            let show_rationale_question = default_show_rationale_question;

            if(proj_prog_config && proj_prog_config.sb.meeting_request.show_rationale_question != undefined){
                show_rationale_question = proj_prog_config.sb.meeting_request.show_rationale_question;
            }

            if(show_rationale_question === true){
                this.requiredFields.mf_meeting.rationale_id = 1;
            }

            if(proj_prog_config && (proj_prog_config.sb.meeting_request?.Check_budget_by_Sales_Force_selection ?? false))
            {
                this.requiredFields.mf_meeting.sf_budget_position = 1;
            }

            for(let i in this.formCards)
            {
                this.formCards[i].setRequiredFields(this.requiredFields);
            }

            this.formCards.project_card.applyRequiredFields();
            this.formCards.project_card.isFilledOut();
            this.formCards.project_card.cardFilledOutTriggers();
        })
    }

    requestProgram() {
        if(app.appData.projects[this.formCards.project_card.project_dd.val()].check_budget == "1"
        && parseFloat(this.budget_card.data.total.default) > parseFloat(this.budget_card.data.budget.remaining)) {
            alert("Programming budget has reached capacity with this selection.  Please have a discussion with your manager if you'd like to move forward with this program.");
            return;
        }

        let speakerExpenses = this.budget_card.data.expenses;
        if(app.appData.layout.meeting_request.speaker_brand_program_group_threshold_check == "1") {
            let cappedSpeakers = Array();
            $(this.getSpeakerData()).each( function(index, speaker) {
                let threshold = parseInt(speaker.contracted.threshold_amount);
                let expenses = parseInt(speaker.contracted.expenses);
                $(speakerExpenses).each( function(index, expense) {
                    if(expense.label == "Honoraria (" + speaker.speaker_name + ")" && threshold > 0 && threshold <= (expenses + parseInt(expense.amount)))
                    {
                        cappedSpeakers.push(speaker.speaker_name);
                    }
                });
            });

            if(Object.keys(cappedSpeakers).length > 0)
            {
                displayNotification({
                    'title'     : 'Meeting could not be submitted due to the following speakers exceeding their threshold cap upon successful submission:<br/>' +
                    cappedSpeakers.join('<br/>') + '<br/>Please contact your program coordinator or select another speaker.'
                });
                return;
            }
        }

        let proj_prog_config = this.getProjectProgramTypeConfig();
        if((proj_prog_config && proj_prog_config.sb.meeting_request.display_faculty_prep_section)
            || (!proj_prog_config && app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.display_faculty_prep_section)) {
            let prep_id = 0;
            let missing_pd = false;
            this.dom.find('.form-check-yes').each( function(i, el) {
                if($(el).is(":checked")) {
                    prep_id = $(el).data('speaker-id');
                    if($('#pref_prep_call_date_' + prep_id).val() == '' || $('#pref_prep_call_time_hr_ampm_' + prep_id).val() == '') {
                        missing_pd = true;
                    }
                }
            });
            if(missing_pd) {
                displayNotification({
                    'title'     : 'Meeting could not be submitted due to missing prep call scheduling data.'
                });
                return;
            }
        }

        let request_data = this.getRequestData();

        this.dom.find('#submit_request').hide();
        this.dom.find('#submit_request_loading').show();

        return $.ajax({
            url     : 'api/meeting',
            method  : 'post',
            data    : request_data
        }).then((data) => {
            if(!data.valid) {
                displayNotification({
                    'title'     : 'Meeting could not be submitted',
                    'message'   : 'Your session has likely timed out, please try refreshing the page and try again.'
                });
            }
            else {
                let destination = typeof data.redirect == 'undefined' ? 'meeting' : data.redirect;
                this.formCards.project_card.project_dd.val('');
                this.formCards.project_card.project_dd.trigger('change');
                this.formCards.speaker_card.availabilities = {};

                let status;
                if(request_data.consultation_reason) {
                    status = 'inform'
                }

                app.appData.request_message = {
                    message: data.message,
                    status: status
                };

                app.appData.refreshProgramList = true;

                this.budget_card.reset();

                if(window.app.pages.home.budgetCard != undefined) {
                    window.app.pages.home.budgetCard.setApprovalCount();
                }

                this.applyingHub = false;
                app.appData.fromMeetingRequest = true;

                //check if we're uploading an MIRF file
                if($('#upload_mirf_file').val() != undefined && $('#upload_mirf_file').val() != ""){
                    let file = $('#upload_mirf_file')[0].files[0];
                    let form_data = new FormData()
                    form_data.append(0,file);
                    $.ajax({
                        url : 'meeting/' + data.meeting_id + '/uploadMirf',
                        method: 'post',
                        data: form_data,
                        processData: false,
                        contentType: false
                    }).then(() => {
                        app.navigate(destination, [data.meeting_id])
                    })
                }else{
                    app.navigate(destination, [data.meeting_id])
                }
            }

            this.dom.find('#submit_request_loading')
                .html('If you are still here, something went wrong. Please reload the page.');
            //this.dom.find('#submit_request').show();
        })
    }

    bindSideCardEvents() {
        $(".speaker_overlay").on('click', () => {
            $(".speaker_overlay").hide();
            $(".speaker_side_card").hide();
        })
    }

    ready() {
        $('#request_program_button').hide();

        this.bindSideCardEvents();

        // reset submit button and text
        this.dom.find('#submit_request').show();
        this.dom.find('#submit_request_loading').hide();
        this.dom.find('#submit_request_loading')
                .html('Processing...');
    }

    teardown() {
        $('#request_program_button').show();
    }

    calculateDistance() {
        return new Promise((resolve) => {
            let venue_data = this.formCards.venue_card.getVenueData();
            if(venue_data.primary.venue_address1 != '' && venue_data.primary.venue_city != '' && venue_data.primary.venue_state != '' && venue_data.primary.venue_zip != '') {
                let venue_address = venue_data.primary.venue_address1 + ' ' + venue_data.primary.venue_city + ', ' + venue_data.primary.venue_state + ' ' + venue_data.primary.venue_zip + ' ' + venue_data.primary.venue_country;

                this.dom.find(".rec_address").each((i, el) => {
                    if($(el).val() != ''){
                        let speaker_addy = $(el).val();
                        this.getTravelDistance([speaker_addy], venue_address, (data) => {
                            let route = null;
                            if(app.appData.configs.GET_SHORTEST_ROUTE || false) {
                                route = this.getShortestRoute(data.routes)
                            } else {
                                route = this.getLongestRoute(data.routes)
                            }
                            let distance_mi = (data.routes[route].legs[0].distance.value * 0.000621371192).toFixed(2);
                            $(el).closest('.form-group').find('.rec_mtm').val(distance_mi);
                            let ppconfig = this.getProjectProgramTypeConfig();
                            if(ppconfig.sb.meeting_request.travel_arrangements_over_100_miles && parseInt(distance_mi) >= 100) {
                                this.showTravelArrangementRecommendation($(el).parents('.speaker_selection_container'), distance_mi);
                            }else{
                                this.hideTravelArrangementRecommendation();
                            }
                            this.budget_card.update(this.getRequestData());
                        });

                    }
                });

                resolve(true);
            } else {
                resolve(true);
            }
        });
    }

    getTravelDistance(origin, dest, callback) {
        let directionsService = new google.maps.DirectionsService();
        let dir_request = {
            origin: '"'+origin+'"',
            destination: '"'+dest+'"',
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
            provideRouteAlternatives : true
        }

        directionsService.route(dir_request, callback);

    }

    getLongestRoute(routes) {
        let maxDuration = 0;
        let routeIndex = 0;

        for(let i=0; i<routes.length; i++) {
            if(routes[i].legs[0].duration.value > maxDuration) {
                maxDuration = routes[i].legs[0].duration.value;
                routeIndex = i;
            }
        }
        return routeIndex;
    }

    getShortestRoute(routes) {
        let minDistance = routes[0].legs[0].distance.value;
        let routeIndex = 0;

        for(let i=0; i<routes.length; i++)
        {
            if(routes[i].legs[0].distance.value <= minDistance)
            {
                minDistance = routes[i].legs[0].distance.value;
                routeIndex = i;
            }
        }

        return routeIndex;
    }

    showTravelArrangementRecommendation($speaker_section_container, distance_mi) {
        let $chk = $speaker_section_container.find('.need_travel_arrangements');
        let id = $chk.attr('id');

        if(!this.nta_runonce.includes(id)){
            if(!$chk.prop('checked')){

                // Show approximated distance
                let round_hundred = Math.floor(parseInt(distance_mi)/100)*100;
                $speaker_section_container.find('.need_travel_arrangements_distance').text(round_hundred + ' miles');

                // Emphasize travel arrangements section(s) needing attention
                $speaker_section_container.find('.travel_details').css('border', '1px dashed #f00');

                // Auto-scroll to the first speaker section since there may be multiple and this makes them all visible
                let $first_speaker_section = $(".speaker_selection_container").eq(0);
                $first_speaker_section.scrollParent().animate({
                    scrollTop: $first_speaker_section.offset().top - $(".content-body").offset().top
                }, 1000, () => {
                    // Visually show which speaker section(s) need addressing and click the needs travel arrangements box
                    $speaker_section_container.find('.need_travel_arrangements_suggestion').slideDown(1000, () => {
                        $chk.click();
                    });
                });

                this.nta_runonce.push(id);
            }
        }
    }

    hideTravelArrangementRecommendation() {
        $('.travel_details').css('border', '');
        $('.need_travel_arrangements_suggestion').hide();
    }
}
