import {Card} from "./Card";
import {SpeakerSlot} from "./SpeakerSlot";
import {CustomModal} from "../../components/customModal";

var speaker_card_template = require('./speaker_card.twig');

export class SpeakerCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: speaker_card_template}).render({
            labels: app.appData.labels
        }));

        this.speaker_slots = [];

        this.dom.find('#add_topic_button').on('click', () => this.addTopic() )

        this.dom.on('SlotFillUpdate', () => this.cardFilledOutTriggers());

        this.dom.on('SpeakerRemoved', (event) => {
            this.speaker_slots.splice(this.speaker_slots.indexOf(event.slot), 1);
            this.updateAddRemoveSlot();
            this.dom.trigger('NeedBudgetUpdate');
            this.dom.trigger('UpdatePrep');
            this.cardFilledOutTriggers();
        })

        this.dom.on('SpeakerChanged', (event) => {
            let speaker_id = $(event.target).find('.speaker_dd').val();
            if(app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.force_same_speaker && $('.speaker_dd').length > 1) {
                this.speaker_id = speaker_id;
                let primary_changed = false;
                for(let i in this.speaker_slots) {
                    let $primary = this.speaker_slots[i].dom.find('.speaker_dd').first();
                    if($primary.val() != speaker_id){
                        $primary.val(speaker_id).select2();
                        primary_changed = true;
                    }
                }

                if(primary_changed){
                    // Notify same speaker enabled and refresh speaker side card
                    alert('All speakers will be changed since this project/program type requires same speaker selection');
                }
            }
            this.dom.trigger('NeedBudgetUpdate');

            if(app.appData.layout.meeting_request.show_speaker_side_card)
            {
                this.dom.trigger('UpdateSpeakerSideCards');
            }

            this.cardFilledOutTriggers();
        })

        this.dom.on('SpeakerDDRedraw', (event) => {
            if(app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.force_same_speaker
                && this.speaker_id) {
                $(event.target).find('.speaker_dd').first().val(this.speaker_id).select2();
                this.dom.trigger('NeedBudgetUpdate');
            }
            this.cardFilledOutTriggers();
        })

        this.dom.on('SpeakerLocChanged', () => {
            this.cardFilledOutTriggers();
        })

        this.dom.on("checkTopicLength", (event) => {
            let topics = [];
            let attestedTopics = [];
            let total_topic_length = 0;
            
            let selectedTopic = event.slot === 0 ? "" : event.slot.topic_dd.val();
            let currentSlotContainer = event.slot === 0 ? null : event.slot.topic_dd.closest("div");
            let currentSlotAttestTime = currentSlotContainer ? currentSlotContainer.find("#topic_attestation_time").val() : "";
            
            let attestation_config = this.speaker_slots[0].attestation_config;
        
            if (this.speaker_slots[0].topic_length_attestation) {
                for (let currentSlot of this.speaker_slots) {
                    let topic = currentSlot.topic_dd.val();
                    let selectedOption = currentSlot.topic_dd.find(":selected");
                    let topic_length = selectedOption.data("show-length");
                    let attestationTime = currentSlotContainer ? currentSlotContainer.find("#topic_attestation_time").val() : "";
                    let attestedTopic = currentSlotContainer ? currentSlotContainer.find("#topic_attestation_time").attr("data-topic-id") : "";
        
                    if (topic && topic_length) {
                        topics.push(topic);
                        total_topic_length += parseFloat(topic_length);
                    }
                    if (attestationTime) {
                        attestedTopics.push(attestedTopic);
                    }
                }
        
                let requiresAttestation = !attestedTopics.includes(selectedTopic) || event.slot == 0 || currentSlotAttestTime == "";

                if(event.slot == 0 && !this.checkTopicLengthAttestation(total_topic_length, attestation_config, $('#time_dd').val())) {
                    this.clearTopicAttesationTimeStamps();
                }

                if (requiresAttestation && this.checkTopicLengthAttestation(total_topic_length, attestation_config, $('#time_dd').val())) {
                    let data = topics.length > 1 ? attestation_config.multiple_info : attestation_config.single_info;
                    let modalData = data.replace("<selected_Topic_length>", total_topic_length);
                    let modalContent = $(twig({ data: modalData }).render());
        
                    let attestation = new CustomModal({
                        content: modalContent,
                        title: "Attestation Required",
                        static: true,
                        cross_button: true,
                        buttons: [
                            {
                                text: "I attest",
                                close: true,
                                class: "btn-primary",
                                id: "confirm_attest",
                                action: () => {
                                    this.captureAttestationTimestamp(event.slot);
                                },
                            },
                            {
                                text: "Cancel",
                                close: true,
                                class: "btn-danger",
                                id: "cancel_attest",
                                action: () => {
                                    this.clearTopics(event.slot);
                                },
                            },
                        ],
                    });
        
                    attestation.open();
                }
            }
        });
        this.availabilities = {};
    }

    addTopic() {
        let speaker_slot = new SpeakerSlot();
        this.dom.find('#add_topic_container').before(speaker_slot.dom);
        this.speaker_slots.push(speaker_slot);
        this.updateAddRemoveSlot();
        this.applyRequiredFields();
        if (Object.keys(speaker_slot.topics).length == 1) {
            this.dom.trigger($.Event('checkTopicLength', {slot: speaker_slot}))
        }
    }

    updateAddRemoveSlot() {
        if(this.speaker_slots.length < app.appData.projects[$('#project_dd').val()].max_speakers) {
            this.dom.find('#add_topic_container').show();
        }
        else {
            this.dom.find('#add_topic_container').hide();
        }

        if(this.speaker_slots.length > app.appData.projects[$('#project_dd').val()].min_speakers) {
            this.dom.find('.remove_topic_button').show();
        }
        else {
            this.dom.find('.remove_topic_button').hide();
        }
    }

    refreshTopics(){
        for(let i in this.speaker_slots) {
            this.speaker_slots[i].drawTopicDD();
        }
    }

    show(){
        let proj_prog_type_conf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
        let instructions = proj_prog_type_conf.sb.meeting_request.speakers_instructions;
        if(typeof instructions != 'undefined' && instructions != '') {
            $('#speakers_instructions').text(instructions).show();
        } else {
            $('#speakers_instructions').hide();
        }

        while(this.speaker_slots.length < app.appData.projects[$('#project_dd').val()].min_speakers)
        {
            this.addTopic();
        }
        this.updateAddRemoveSlot();
        super.show();
    }

    clear() {
        // 💩
        if($('#hub_meeting_id').val() !== '' && typeof $('#hub_meeting_id').val() != 'undefined')
        {
            return;
        }

        this.speaker_id = null;

        let num_slots = this.speaker_slots.length;
        for(let i = 0; i < num_slots; i++) {
            this.speaker_slots[i].dom.detach();
        }

        this.speaker_slots = [];

        this.dom.trigger('ClearSpeakerSideCard');

        super.clear();
    }

    isFilledOut() {
        let speakertopics = [];
        let duplicates = false;
        let available = true;
        let location_filled = true;
        let local_cap_maxed_count = 0;
        Object.entries(this.speaker_slots).forEach(entry => {
            let slot = entry[1];
            let topic_id = slot.topic_dd.val();

            if(typeof speakertopics[topic_id] == 'undefined') {
                speakertopics[topic_id] = [];
            }

            slot.dom.find('.speaker_dd, .patient_speaker_dd').each((i, speaker_dd) => {
                let speaker_id = $(speaker_dd).val();
                if(typeof speakertopics[topic_id][speaker_id] !== 'undefined'
                    && speakertopics[topic_id][speaker_id]) {
                    duplicates = true;
                    this.dom.find('.warning-duplicate').each((i, element) => { $(element).show(200) });
                } else if(speaker_id) {
                    speakertopics[topic_id][speaker_id] = 1
                }

                if(i == 0){//only check for primary speaker
                    let searchParams = {
                        speaker_id: speaker_id,
                        meeting_date: $('#meeting_date').val()
                    };

                    let searchParamsString = JSON.stringify(searchParams);

                    if(typeof(this.availabilities[searchParamsString]) != 'undefined') {
                        available = this.availabilities[searchParamsString];
                        if(!available) {
                            this.dom.find('.warning-unavailable').each((i, element) => { $(element).show(200) });
                        }
                    } else {
                        //check for Speaker Availability.
                        $.ajax({
                            url     : "api/meetingrequest/speakeravailability",
                            method  : 'get',
                            async   : false,
                            data    : {
                                speaker_id: speaker_id,
                                meeting_date: $('#meeting_date').val()
                            }
                        }).done((data) => {
                            this.availabilities[searchParamsString] = data.success;
                            if(data.success == false){
                                available = false;
                                this.dom.find('.warning-unavailable').each((i, element) => { $(element).show(200) });
                            }
                        });
                    }

                }

                if($(speaker_dd).hasClass( "speaker_local_cap_maxed")){
                    local_cap_maxed_count++;
                }

            })

            slot.dom.find('.speaker_location_dd').each((i,speaker_loc) => {
                let location = $(speaker_loc).val();

                if(location == '') {
                    location_filled = false;
                }
            })

        })

        if(!duplicates) {
            this.dom.find('.warning-duplicate').each((i, element) => { $(element).hide(200) });
        }

        if(available) {
            this.dom.find('.warning-unavailable').each((i, element) => { $(element).hide(200) });
        }

        // check if short lead form email card is displayed
        let slt_form_email_card_vis = false;
        if($('#slt_form_email_card').is(':visible')){
            slt_form_email_card_vis = true;
            $('#speaker_card').hide();
        }

        return available && !duplicates && !(local_cap_maxed_count > 0) && (location_filled) && !slt_form_email_card_vis && super.isFilledOut();
    }

    parseTime(timeStr) {
        return moment(timeStr, 'h:mm:a');
    }

    checkTopicLengthAttestation(topic_length, config, program_time) {
        if (program_time == "") {
            return false;
        }
        let time = this.parseTime(program_time);
        let startTime = this.parseTime(config.start_time);
        let endTime = this.parseTime(config.end_time);
        if ((time.isAfter(startTime) || time.isSame(startTime)) &&
            (time.isBefore(endTime) || time.isSame(endTime))) {
            if ((topic_length != 0 && topic_length != undefined && topic_length > 0 && topic_length > config.limit)) {
                return true;
            }
        }
        return false;
    }


    captureAttestationTimestamp(slot) {
        let timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
        let slotsToUpdate = (slot === 0) ? this.speaker_slots : [slot];
    
        slotsToUpdate.forEach(currentSlot => {
            let topicInput = currentSlot.topic_dd.closest("div").find("#topic_attestation_time");
            topicInput.val(timestamp);
            topicInput.attr("data-topic-id", currentSlot.topic_dd.val());
        });
    }
    

    clearTopics(slot) {
        let slotsToClear = (slot === 0) ? this.speaker_slots : [slot];
    
        slotsToClear.forEach(currentSlot => {
            let topicInput = currentSlot.topic_dd;
            let topicDiv = topicInput.closest("div");
    
            topicInput.val(null);
            topicDiv.find("#topic_attestation_time").val(null).attr("data-topic-id", "");
            topicDiv.parent().find("#topic_duration #topic_length").val(null);
            
            currentSlot.drawSpeakerDDs();
            currentSlot.triggerSlotFilledEvent();
        });
    }

    clearTopicAttesationTimeStamps() {
        this.speaker_slots.forEach(currentSlot => {
            currentSlot.topic_dd.parent().find("#topic_attestation_time").val(null).attr("data-topic-id", "");
        });
    }

    triggerTopiclength() {
        this.dom.trigger($.Event('checkTopicLength', {slot: 0}));
    }
}
